import Vue from 'vue'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import {
  Menu,
  MenuItem,
  Submenu,
  Carousel,
  CarouselItem,
  Drawer
} from 'element-ui'

Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Carousel)
Vue.use(Submenu)
Vue.use(CarouselItem)
Vue.use(Drawer)
Vue.component(CollapseTransition.name, CollapseTransition)
