<template>
  <div id="app">
    <!-- 首页 -->
    <Home></Home>
  </div>
</template>

<script>
import Home from '@/views/home'
export default {
  name: 'APP',
  components: { Home },
  created() {
  }
}
</script>

<style>
html,body,#app{ height:100%; }
</style>
