<template>
  <div v-if="!isMobile" class="content-pc" ref="content">
    <div class="header">
      <div class="header-content">
        <div class="logo" @click="pageScrollTo('layout','1')">
          <img src="../../assets/images/logo.png" alt="">
          <h1>行影不离智能科技</h1>
        </div>
        <div class="menu">
          <el-menu text-color="#111C61" active-text-color="#0650FF" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item @click="pageScrollTo('layout','1')" index="1">首页
              <div v-show="activeIndex == '1'" class="menu-buttom">
                <div class="active-line"></div>
              </div>
            </el-menu-item>
            <el-menu-item @click="pageScrollTo('service','2')" index="2">产品服务
              <div v-show="activeIndex == '2'" class="menu-buttom"><div class="active-line"></div></div>
            </el-menu-item>
            <el-submenu index="3">
              <template slot="title">解决方案</template>
              <el-menu-item style="color:#fff" @click="pageScrollTo('layout','2-1')" index="2-1">城市停车</el-menu-item>
              <el-menu-item style="color:#fff" @click="pageScrollTo('layout','2-2')" index="2-2">城市云平台</el-menu-item>
              <el-menu-item style="color:#fff" @click="pageScrollTo('layout','2-3')" index="2-3">室内导航</el-menu-item>
              <el-menu-item style="color:#fff" @click="pageScrollTo('layout','2-4')" index="2-4">节能产品</el-menu-item>
              <el-menu-item style="color:#fff" @click="pageScrollTo('layout','2-5')" index="2-5">场库智慧化建设和运营</el-menu-item>
            </el-submenu>
            <el-menu-item @click="pageScrollTo('about','4')" index="4">关于我们
              <div v-show="activeIndex == '4'" class="menu-buttom"><div class="active-line"></div></div>
            </el-menu-item>
            <el-menu-item @click="pageScrollTo('contact','4')" index="5">联系我们
              <div v-show="activeIndex == '5'" class="menu-buttom"><div class="active-line"></div></div>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>
    <div class="scroll-content">
      <div class="layout">
        <div class="banner">
          <van-swipe @change="changeIndicator" ref="swiper" :show-indicators="false" class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-if="activeIndex.length == 1">
              <img src="../../assets/images/banner-01.png" alt="">
            </van-swipe-item>
            <van-swipe-item v-if="activeIndex.length == 1">
              <img src="../../assets/images/banner-02.png" alt="">
            </van-swipe-item>
            <van-swipe-item v-if="activeIndex === '2-1'">
              <img src="../../assets/images/bannerPc-2-1.png" alt="">
            </van-swipe-item>
            <van-swipe-item v-if="activeIndex === '2-2'">
              <img src="../../assets/images/bannerPc-2-2.png" alt="">
            </van-swipe-item>
            <van-swipe-item v-if="activeIndex === '2-3'">
              <img src="../../assets/images/bannerPc-2-3.png" alt="">
            </van-swipe-item>
            <van-swipe-item v-if="activeIndex === '2-4'">
              <img src="../../assets/images/bannerPc-2-4.png" alt="">
            </van-swipe-item>
            <van-swipe-item v-if="activeIndex === '2-5'">
              <img src="../../assets/images/bannerPc-2-5.png" alt="">
            </van-swipe-item>
          </van-swipe>
          <div class="indicators" v-if="activeIndex.length == 1">
            <div @click="onClickIndicator(0)" :class="swiperIndex == 0 ? 'van-swipe__indicator--active' : 'van-swipe__indicator'"></div>
            <div @click="onClickIndicator(1)" :class="swiperIndex == 1 ? 'van-swipe__indicator--active' : 'van-swipe__indicator'"></div>
          </div>
        </div>
      </div>
      <div v-show="activeIndex.length !== 1" class="goodness">
        <div class="title">产品优势</div>
        <div class="goodness-content">
          <div class="left">
            <div class="goodness-list">
              <div class="list-title">
                <img :src="listTitleImg1" alt="">
                <span>{{goodnessListTitle1}}</span>
              </div>
              <div class="list-mian">{{goodnessList1}}</div>
            </div>
            <div v-show="activeIndex !== '2-4'" class="goodness-list">
              <div class="list-title">
                <img :src="listTitleImg2" alt="">
                <span>{{goodnessListTitle2}}</span>
              </div>
              <div class="list-mian">{{goodnessList2}}</div>
            </div>
          </div>
          <div class="right">
            <img :src="goodnessImg" alt="">
          </div>
        </div>
      </div>
      <div v-show="activeIndex.length == 1" class="service">
        <div class="title">产品服务</div>
        <div class="service-content">
          <div class="service-btn">
            <div @click="changeServiceType(1)" :class="serviceType == 1? 'btn-list active-btn' : 'btn-list'"><div class="border-bottom"></div>
              <img v-if="serviceType == 1" src="../../assets/images/active-service-btn-01.png" alt="">
              <img v-else src="../../assets/images/service-btn-01.png" alt="">
              <span>城市交通</span>
             </div>
            <div @click="changeServiceType(2)" :class="serviceType == 2? 'btn-list active-btn' : 'btn-list'"><div class="border-bottom"></div>
              <img v-if="serviceType == 2" src="../../assets/images/active-service-btn-02.png" alt="">
              <img v-else src="../../assets/images/service-btn-02.png" alt="">
              <span>汽车服务</span>
             </div>
            <div @click="changeServiceType(3)" :class="serviceType == 3? 'btn-list active-btn' : 'btn-list'"><div class="border-bottom"></div>
              <img v-if="serviceType == 3" src="../../assets/images/active-service-btn-03.png" alt="">
              <img v-else src="../../assets/images/service-btn-03.png" alt="">
              <span>城市商业</span>
             </div>
            <div @click="changeServiceType(4)" :class="serviceType == 4? 'btn-list active-btn' : 'btn-list'"><div class="border-bottom"></div>
              <img v-if="serviceType == 4" src="../../assets/images/active-service-btn-04.png" alt="">
              <img v-else src="../../assets/images/service-btn-04.png" alt="">
              <span>AI智能</span>
             </div>
          </div>
          <div class="service-img">
            <img v-show="serviceType == 1" src="../../assets/images/serviceImg1.gif" alt="">
            <img v-show="serviceType == 2" src="../../assets/images/serviceImg2.gif" alt="">
            <img v-show="serviceType == 3" src="../../assets/images/serviceImg3.gif" alt="">
            <img v-show="serviceType == 4" src="../../assets/images/serviceImg4.gif" alt="">
          </div>
        </div>
      </div>
      <div v-show="activeIndex.length !== 1" class="service-copy">
        <div class="title">产品及服务</div>
        <div class="service-content">
          <div @mouseenter="handelMouseenter(1)" @mouseleave="handelMouseenter(null)" class="service-list">
            <div v-show="tipStatus == 1 && activeIndex !== '2-4'" class="streamer">{{streamer1}}</div>
            <img :src="serviceList1" alt="">
            <div v-if="activeIndex !== '2-4' && activeIndex !== '2-2'">{{serviceContent1}}</div>
            <div v-show="activeIndex == '2-1'" class="tip">*示例图：长沙易停车APP</div>
          </div>
          <div @mouseenter="handelMouseenter(2)" @mouseleave="handelMouseenter(null)" class="service-list">
            <div v-show="tipStatus == 2 && activeIndex !== '2-4' && activeIndex !== '2-2'" class="streamer">{{streamer2}}</div>
            <img :src="serviceList2" alt="">
            <div>{{serviceContent2}}</div>
          </div>
          <div v-if="activeIndex == '2-4' || activeIndex == '2-2'" class="inline-service-list">{{serviceContent1}}</div>
          <div @mouseenter="handelMouseenter(3)" @mouseleave="handelMouseenter(null)" v-show="activeIndex !== '2-4' && activeIndex !== '2-2'" class="service-list">
            <div v-show="tipStatus == 3" class="streamer">{{streamer3}}</div>
            <img :src="serviceList3" alt="">
            <div>{{serviceContent3}}</div>
          </div>
          <div @mouseenter="handelMouseenter(4)" @mouseleave="handelMouseenter(null)" v-show="activeIndex !== '2-4' && activeIndex !== '2-2'" class="service-list">
            <div v-show="tipStatus == 4" class="streamer">{{streamer4}}</div>
            <img :src="serviceList4" alt="">
            <div>{{serviceContent4}}</div>
          </div>
          <div @mouseenter="handelMouseenter(5)" @mouseleave="handelMouseenter(null)" v-show="activeIndex === '2-5'" class="service-list">
            <div v-show="tipStatus == 5" class="streamer">{{streamer5}}</div>
            <img :src="serviceList5" alt="">
            <div>{{serviceContent5}}</div>
          </div>
          <div @mouseenter="handelMouseenter(6)" @mouseleave="handelMouseenter(null)" v-show="activeIndex === '2-5'" class="service-list">
            <div v-show="tipStatus == 6" class="streamer">{{streamer6}}</div>
            <img :src="serviceList6" alt="">
            <div>{{serviceContent6}}</div>
          </div>
        </div>
      </div>
      <div v-show="activeIndex.length == 1" class="about">
        <div class="title">关于我们</div>
        <div class="about-content">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司为知名基金投资高科技项目，立足于基于城市大数据应用方向的智能数据运营，为产业互联网工具引擎类数据营销方向。
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;行影定位于基于城市行业数据的基建基础，进行数据运营智能化引擎开发，基于云平台的技术服务，依据智能数据的城市运营、商业运营支撑系统，智能数据产业环境建设。研发方向包括城市数据自学习系统，城市数据神经网络学习和分布式计算，城市数据对象智能分析系统，智能推荐系统，行为分析，群体分析与判断，VR、AR与全景地图，动态智能交通推荐系统算法，城市交通智能引导优化算法，自动驾驶末端配套等。
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;商业服务领域包括在金融、商业引流、汽车与后汽车市场、商超数据服务、停车和商场精细地图和VR导航服务，物流服务，车联网服务。
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业具备强烈的新时代社会服务属性，通过大数据分析，和舆情收集，信息交叉，为城市工商业布局，城市规划决策，城市交通优化，提供不断优化的数据。
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同时通过智能引擎的产业环境建设，我们将扶持和推动大量的中小企业的数字化转型，以及为中小企业数字化转型提供新型数字化系统的中小特色企业，也是数据孵化器和产业推动引擎。
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司核心团队有资深城市数字停车、城市VR/AR及大数据基础建设领域团队组成，在开设之初，就与中国著名高校具备国家领先型的实验室合作，进行产学研联合发展。
        </div>
      </div>
      <div v-show="activeIndex.length == 1" class="contact">
        <div class="title">联系我们</div>
        <div class="contact-content">
          <div class="part-1">
            <div class="row-1">商务合作</div>
            <div class="row-2">bd@sciencewalk.net</div>
          </div>
          <div class="part-2">
            <div class="row-1">招聘</div>
            <div class="row-2">sw.hr@sciencewalk.net</div>
          </div>
          <div class="part-1">
            <div class="row-1">总部</div>
            <div class="row-2">上海市徐汇区宛平南路1099号城建大厦305室</div>
          </div>
          <div class="part-2">
            <div class="row-1">分公司</div>
            <div class="row-2">长沙市岳麓区观沙岭街道茶子山东路112号湘江财富金融中心D区1906 室</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-content">
          <div class="list">© 2012-2023 行影不离智能科技（上海）有限公司</div>
          <div class="list">
            <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo">沪公安网备案 31010102004544号</a>
          </div>
          <div class="list">
            <a target="_blank" href="https://beian.miit.gov.cn">沪ICP备2021019798号-1</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="content-mobile" ref="content">
    <div class="header">
      <div class="logo" @click="pageScrollTo('layout','1')">
        <img src="../../assets/images/logo.png" alt="">
      </div>
      <div @click="showMenu = true" class="menu">
        <div class="menu-text">菜单</div>
        <img src="../../assets/images/menu.png" alt="">
      </div>
    </div>
    <!-- 遮罩层 -->
    <el-drawer
        title="我是标题"
        :visible.sync="showMenu"
        size="60%"
        :with-header="false">
        <div class="menu">
          <div class="menu-text">菜单</div>
          <img class="menu-close" @click="showMenu = false" src="../../assets/images/menu-close.png" alt="">
        </div>
        <div class="menu-list">
          <div @click="pageScrollTo('layout','1')" :class="activeIndex === '1' ? 'menu-title menuActive':'menu-title'">
            <span>首页</span>
          </div>
          <div @click="pageScrollTo('service','2')" :class="activeIndex === '2' ? 'menu-title menuActive':'menu-title'">
            <span>产品服务</span>
          </div>
          <div @click="HandleShowMenuFlag" :class="activeIndex.length !== 1 ? 'menu-title menuActive':'menu-title'">
            <span>解决方案</span>
            <img :class="showMenuFlag? '':'menu-not-show'" src="../../assets/images/menu-show.png" alt="">
          </div>
          <el-collapse-transition>
            <div class="menu-group" v-if="showMenuFlag">
              <div @click="pageScrollTo('layout','2-1')" :class="activeIndex == '2-1' ? 'menu-items menuActive':'menu-items'">城市停车</div>
              <div @click="pageScrollTo('layout','2-2')" :class="activeIndex == '2-2' ? 'menu-items menuActive':'menu-items'">城市云平台</div>
              <div @click="pageScrollTo('layout','2-3')" :class="activeIndex == '2-3' ? 'menu-items menuActive':'menu-items'">室内导航</div>
              <div @click="pageScrollTo('layout','2-4')" :class="activeIndex == '2-4' ? 'menu-items menuActive':'menu-items'">节能产品</div>
              <div @click="pageScrollTo('layout','2-5')" :class="activeIndex == '2-5' ? 'menu-items menuActive':'menu-items'">场库智慧化建设和运营</div>
            </div>
          </el-collapse-transition>
          <div @click="pageScrollTo('about','4')" :class="activeIndex === '4' ? 'menu-title menuActive':'menu-title'">
            <span>关于我们</span>
          </div>
          <div @click="pageScrollTo('contact','5')" :class="activeIndex === '5' ? 'menu-title menuActive':'menu-title'">
            <span>联系我们</span>
          </div>
        </div>
    </el-drawer>
    <div class="scroll-content">
      <div class="layout">
        <div class="banner-mobile">
          <van-swipe :show-indicators="true" class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-if="activeIndex.length == 1">
              <img src="../../assets/images/bannerMobile-01.png" alt="">
            </van-swipe-item>
            <van-swipe-item v-if="activeIndex.length == 1">
              <img src="../../assets/images/bannerMobile-02.png" alt="">
            </van-swipe-item>
            <van-swipe-item v-if="activeIndex === '2-1'">
              <img src="../../assets/images/banner-2-1.png" alt="">
            </van-swipe-item>
            <van-swipe-item v-if="activeIndex === '2-2'">
              <img src="../../assets/images/banner-2-2.png" alt="">
            </van-swipe-item>
            <van-swipe-item v-if="activeIndex === '2-3'">
              <img src="../../assets/images/banner-2-3.png" alt="">
            </van-swipe-item>
            <van-swipe-item v-if="activeIndex === '2-4'">
              <img src="../../assets/images/banner-2-4.png" alt="">
            </van-swipe-item>
            <van-swipe-item v-if="activeIndex === '2-5'">
              <img src="../../assets/images/banner-2-5.png" alt="">
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
      <div v-show="activeIndex.length == 1" class="service">
        <div class="title">
          产品服务
        </div>
        <div class="service-content">
          <div class="service-btn">
            <div @click="changeServiceType(1)" :class="serviceType == 1? 'btn-list active-btn' : 'btn-list'">
               城市交通
            </div>
            <div @click="changeServiceType(2)" :class="serviceType == 2? 'btn-list active-btn' : 'btn-list'">
              汽车服务
            </div>
            <div @click="changeServiceType(3)" :class="serviceType == 3? 'btn-list active-btn' : 'btn-list'">
               城市商业
            </div>
            <div @click="changeServiceType(4)" :class="serviceType == 4? 'btn-list active-btn' : 'btn-list'">
               AI智能
            </div>
          </div>
          <div class="service-img">
            <img v-show="serviceType == 1" :src="serviceImg1" alt="">
            <img v-show="serviceType == 2" :src="serviceImg2" alt="">
            <img v-show="serviceType == 3" :src="serviceImg3" alt="">
            <img v-show="serviceType == 4" :src="serviceImg4" alt="">
          </div>
        </div>
      </div>
      <div v-show="activeIndex.length !== 1" class="goodness">
        <div class="title">产品优势</div>
        <div class="goodness-content">
          <div class="top">
            <img :src="goodnessImg" alt="">
          </div>
          <div class="bottom">
            <div class="goodness-list">
              <div class="list-title">
                <img :src="listTitleImg1" alt="">
                <span>{{goodnessListTitle1}}</span>
              </div>
              <div class="list-mian">{{goodnessList1}}</div>
            </div>
            <div v-show="activeIndex !== '2-4'" class="goodness-list">
              <div class="list-title">
                <img :src="listTitleImg2" alt="">
                <span>{{goodnessListTitle2}}</span>
              </div>
              <div class="list-mian">{{goodnessList2}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="activeIndex.length !== 1" class="service-copy">
        <div class="title">产品及服务</div>
        <div class="service-content">
          <div class="service-list">
            <img :src="serviceList1" alt="">
            <div v-show="activeIndex !== '2-4'" class="streamer">{{streamer1}}</div>
            <div v-if="activeIndex !== '2-4' && activeIndex !== '2-2'">{{serviceContent1}}</div>
            <div v-show="activeIndex == '2-1'" class="tip">*示例图：长沙易停车APP</div>
          </div>
          <div class="service-list">
            <img :src="serviceList2" alt="">
            <div>{{serviceContent2}}</div>
          </div>
          <div v-if="activeIndex == '2-4' || activeIndex == '2-2'" class="inline-service-list">{{serviceContent1}}</div>
          <div v-show="activeIndex !== '2-4' && activeIndex !== '2-2'" class="service-list">
            <img :src="serviceList3" alt="">
            <div>{{serviceContent3}}</div>
          </div>
          <div v-show="activeIndex !== '2-4' && activeIndex !== '2-2'" class="service-list">
            <img :src="serviceList4" alt="">
            <div>{{serviceContent4}}</div>
          </div>
          <div v-show="activeIndex === '2-5'" class="service-list">
            <img :src="serviceList5" alt="">
            <div>{{serviceContent5}}</div>
          </div>
          <div v-show="activeIndex === '2-5'" class="service-list">
            <img :src="serviceList6" alt="">
            <div>{{serviceContent6}}</div>
          </div>
        </div>
      </div>
      <div v-show="activeIndex.length == 1" class="about">
        <div class="title">关于我们</div>
        <div class="about-content">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司为知名基金投资高科技项目，立足于基于城市大数据应用方向的智能数据运营，为产业互联网工具引擎类数据营销方向。
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;行影定位于基于城市行业数据的基建基础，进行数据运营智能化引擎开发，基于云平台的技术服务，依据智能数据的城市运营、商业运营支撑系统，智能数据产业环境建设。研发方向包括城市数据自学习系统，城市数据神经网络学习和分布式计算，城市数据对象智能分析系统，智能推荐系统，行为分析，群体分析与判断，VR、AR与全景地图，动态智能交通推荐系统算法，城市交通智能引导优化算法，自动驾驶末端配套等。
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;商业服务领域包括在金融、商业引流、汽车与后汽车市场、商超数据服务、停车和商场精细地图和VR导航服务，物流服务，车联网服务。
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业具备强烈的新时代社会服务属性，通过大数据分析，和舆情收集，信息交叉，为城市工商业布局，城市规划决策，城市交通优化，提供不断优化的数据。
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;同时通过智能引擎的产业环境建设，我们将扶持和推动大量的中小企业的数字化转型，以及为中小企业数字化转型提供新型数字化系统的中小特色企业，也是数据孵化器和产业推动引擎。
          <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司核心团队有资深城市数字停车、城市VR/AR及大数据基础建设领域团队组成，在开设之初，就与中国著名高校具备国家领先型的实验室合作，进行产学研联合发展。
        </div>
      </div>
      <div v-show="activeIndex.length == 1" class="contact">
        <div class="title">联系我们</div>
        <div class="contact-content">
          <div class="part-1">
            <div class="row-1">商务合作</div>
            <div class="row-2">bd@sciencewalk.net</div>
          </div>
          <div class="part-1">
            <div class="row-1">招聘</div>
            <div class="row-2">sw.hr@sciencewalk.net</div>
          </div>
          <div class="part-1">
            <div class="row-1">总部</div>
            <div class="row-2">上海市徐汇区宛平南路1099号城建大厦305室</div>
          </div>
          <div class="part-1">
            <div class="row-1">分公司</div>
            <div class="row-2">长沙市岳麓区观沙岭街道茶子山东路112号湘江财富金融中心D区1906 室</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-content">
          <div>© 2012-2023 行影不离智能科技（上海）有限公司</div>
          <div class="list">
            <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo">沪公安网备案 31010102004544号</a>
          </div>
          <div class="list">
            <a target="_blank" href="https://beian.miit.gov.cn">沪ICP备2021019798号-1</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listTitleImg211 from '../../assets/images/list-title-211.png'
import listTitleImg212 from '../../assets/images/list-title-212.png'
import listTitleImg221 from '../../assets/images/list-title-221.png'
import listTitleImg222 from '../../assets/images/list-title-222.png'
import listTitleImg231 from '../../assets/images/list-title-231.png'
import listTitleImg232 from '../../assets/images/list-title-232.png'
import listTitleImg241 from '../../assets/images/list-title-241.png'
import listTitleImg251 from '../../assets/images/list-title-251.png'
import listTitleImg252 from '../../assets/images/list-title-252.png'
import goodnessImg21 from '../../assets/images/goodness-21.png'
import goodnessImg22 from '../../assets/images/goodness-22.png'
import goodnessImg23 from '../../assets/images/goodness-23.png'
import goodnessImg24 from '../../assets/images/goodness-24.png'
import goodnessImg25 from '../../assets/images/goodness-25.png'
import serviceList211 from '../../assets/images/service-211.png'
import serviceList212 from '../../assets/images/service-212.png'
import serviceList213 from '../../assets/images/service-213.png'
import serviceList214 from '../../assets/images/service-214.png'
import serviceList221 from '../../assets/images/service-221.png'
import serviceList222 from '../../assets/images/service-222.png'
import serviceList231 from '../../assets/images/service-231.png'
import serviceList232 from '../../assets/images/service-232.png'
import serviceList233 from '../../assets/images/service-233.png'
import serviceList234 from '../../assets/images/service-234.png'
import serviceList241 from '../../assets/images/service-241.png'
import serviceList242 from '../../assets/images/service-242.png'
import serviceList251 from '../../assets/images/service-251.png'
import serviceList252 from '../../assets/images/service-252.png'
import serviceList253 from '../../assets/images/service-253.png'
import serviceList254 from '../../assets/images/service-254.png'
import serviceList255 from '../../assets/images/service-255.png'
import serviceList256 from '../../assets/images/service-256.png'
import serviceImg1 from '../../assets/images/serviceImg1.gif'
import serviceImg2 from '../../assets/images/serviceImg2.gif'
import serviceImg3 from '../../assets/images/serviceImg3.gif'
import serviceImg4 from '../../assets/images/serviceImg4.gif'
export default {
  data() {
    return {
      isMobile: false,
      activeIndex: '1',
      showMenu: false,
      showMenuFlag: true,
      goodnessListTitle1: '数字孪生',
      goodnessListTitle2: '智慧停车',
      goodnessList1: '通过与停车硬件结合将停车数据结合全市 交通数据进行研判和分析。',
      goodnessList2: '疏导拥堵和提高车位周转率，提升停车价值。',
      listTitleImg1: listTitleImg211,
      listTitleImg2: listTitleImg212,
      goodnessImg: goodnessImg21,
      streamer1: '停车公众端',
      streamer2: '停车云平台',
      streamer3: '停车运营平台',
      streamer4: '停车监管平台',
      streamer5: '可视对讲一体机',
      streamer6: '车道监控相机',
      serviceList1: serviceList211,
      serviceList2: serviceList212,
      serviceList3: serviceList213,
      serviceList4: serviceList214,
      serviceList5: serviceList255,
      serviceList6: serviceList256,
      serviceContent1: '城市平台停车公众端，为市民提供有APP、小程序、H5多端 TOC的停车查询、在线缴费、车位导航服务。',
      serviceContent2: '通过互联网大数据，提供规范的城市级统一的API接口对接停 车硬件设备、高位视频、地测等数据实现全市“一张网、一平台” ',
      serviceContent3: '城市停车运营主要服务城市商业活动的各种场景，通过精准的 LBS信息更友好的服务客户和顾客。',
      serviceContent4: '服务政府监管，为停车运营企业提供停车备案服务。打造智慧民生工程。',
      serviceContent5: '',
      serviceContent6: '',
      serviceImg: serviceImg1,
      serviceImg1: serviceImg1,
      serviceImg2: serviceImg2,
      serviceImg3: serviceImg3,
      serviceImg4: serviceImg4,
      serviceType: 1,
      swiperIndex: 0,
      tipStatus: null
    }
  },
  mounted() {
    window.document.querySelector('.content-pc').scrollIntoView({ behavior: 'smooth', block: 'start' })
    this.setMobile()
  },
  methods: {
    handleSelect(key) {
      this.activeIndex = key
      switch (key) {
        case '2-1':
          document.title = '行影不离智能科技-解决方案：城市停车'
          return
        case '2-2':
          document.title = '行影不离智能科技-解决方案：城市静态交通智能监管'
          return
        case '2-3':
          document.title = '行影不离智能科技-解决方案：室内导航'
          return
        case '2-4':
          document.title = '行影不离智能科技-解决方案：节能产品'
      }
      document.title = '行影不离智能科技'
    },
    changeServiceType(type) {
      this.serviceType = type
      this.pageScrollTo('service', '2')
      switch (type) {
        case 1:
          this.serviceImg = serviceImg1
          return
        case 2:
          this.serviceImg = serviceImg2
          return
        case 3:
          this.serviceImg = serviceImg3
          return
        case 4:
          this.serviceImg = serviceImg4
      }
    },
    pageScrollTo(menu, activeIndex) {
      this.activeIndex = activeIndex
      this.$nextTick(() => {
        window.document.querySelector('.' + menu).scrollIntoView({ behavior: 'smooth', block: 'start' })
      })
    },
    setMobile() {
      this.isMobile = !this.isPc()
      addEventListener('resize', () => {
        this.isMobile = !this.isPc()
      })
    },
    isPc() {
      if ( // 此处用来检测是否为PC端
        navigator.userAgent.match(/Mobi/i) ||
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        this.$refs.content.clientWidth < 750
      ) {
        return false
      } else {
        return true
      }
    },
    HandleShowMenuFlag() {
      this.showMenuFlag = !this.showMenuFlag
    },
    // 电击指示器切换轮播
    onClickIndicator(index) {
      this.$refs.swiper.swipeTo(index)
    },
    changeIndicator(index) {
      this.swiperIndex = index
    },
    handelMouseenter(val) {
      this.tipStatus = val
    }
  },
  watch: {
    activeIndex(val) {
      this.showMenu = false
      if (val === '2-1') {
        this.listTitleImg1 = listTitleImg211
        this.listTitleImg2 = listTitleImg212
        this.goodnessImg = goodnessImg21
        this.goodnessListTitle1 = '数字孪生'
        this.goodnessListTitle2 = '智慧停车'
        this.goodnessList1 = '通过与停车硬件结合将停车数据结合全市 交通数据进行研判和分析。'
        this.goodnessList2 = '疏导拥堵和提高车位周转率，提升停车价值。'
        this.serviceList1 = serviceList211
        this.serviceList2 = serviceList212
        this.serviceList3 = serviceList213
        this.serviceList4 = serviceList214
        this.streamer1 = '停车公众端'
        this.streamer2 = '停车云平台'
        this.streamer3 = '停车运营平台'
        this.streamer4 = '停车监管平台'
        this.serviceContent1 = '城市平台停车公众端，为市民提供有APP、小程序、H5多端 TOC的停车查询、在线缴费、车位导航服务。'
        this.serviceContent2 = '通过互联网大数据，提供规范的城市级统一的API接口对接停 车硬件设备、高位视频、地测等数据实现全市“一张网、一平台” '
        this.serviceContent3 = '城市停车运营主要服务城市商业活动的各种场景，通过精准的 LBS信息更友好的服务客户和顾客。'
        this.serviceContent4 = '服务政府监管，为停车运营企业提供停车备案服务。打造智慧民生工程。'
      } else if (val === '2-2') {
        this.listTitleImg1 = listTitleImg221
        this.listTitleImg2 = listTitleImg222
        this.goodnessImg = goodnessImg22
        this.goodnessListTitle1 = '数字服务'
        this.goodnessListTitle2 = '智能静态交通'
        this.goodnessList1 = '通过累计的停车数据对全市交通情况进行 提起预判和分析。'
        this.goodnessList2 = '疏导拥堵和提高车位周转率，提升停车价 值，缓解城市交通压力。'
        this.serviceList1 = serviceList221
        this.serviceList2 = serviceList222
        this.streamer1 = '城市静态交通大屏'
        this.serviceContent1 = '城市静态交通监管运营大屏，根据地理位置区域判断停车紧张区域，通过大数据提前预判停车难易程度和交通拥堵情况。通过静态交通辅助城市交通，疏导、缓解城市交通问题。'
        this.serviceContent2 = ''
      } else if (val === '2-3') {
        this.listTitleImg1 = listTitleImg231
        this.listTitleImg2 = listTitleImg232
        this.goodnessImg = goodnessImg23
        this.goodnessListTitle1 = '室内外导航一体化 '
        this.goodnessListTitle2 = '场景导航'
        this.goodnessList1 = '通过与室外地图结合可以做到无感衔接室 内外导航一体化，用户和车主体验感更好。'
        this.goodnessList2 = '商场、机场等大型室内复合型建筑群室内 导航、导购、优惠相结合，支持AR实景导航。'
        this.serviceList1 = serviceList231
        this.serviceList2 = serviceList232
        this.serviceList3 = serviceList233
        this.serviceList4 = serviceList234
        this.streamer1 = '室内外导航'
        this.streamer2 = '停车场室内'
        this.streamer3 = '商场导购'
        this.streamer4 = '机场导购服务'
        this.serviceContent1 = '结合室内外定位技术、物联网技术，自主研发多源智能感知 导航服务，实现对真实世界的数字化识别和空间场景感知。'
        this.serviceContent2 = '通过lot技术结合物联网硬件感知，可以精准到车位导航，是 否有空余可停泊位，让车主停车更加方便。'
        this.serviceContent3 = '商户的精准全程室内导航，让商户营销行为从门店到用户手 里。从服务角度构建基于元宇宙理念的孪生商业形态。'
        this.serviceContent4 = '可对机场航站楼、值机口、登机口、免税店、停车场、卫生间等联动场景进行AR实景导航。'
      } else if (val === '2-4') {
        this.listTitleImg1 = listTitleImg241
        this.goodnessImg = goodnessImg24
        this.goodnessListTitle1 = '数字物联网 '
        this.goodnessList1 = '通过物联网硬件设备组网、人体感应等模 块，云端平台控制实现远程控制达到节电 目的。'
        this.serviceList1 = serviceList241
        this.serviceList2 = serviceList242
        this.serviceContent1 = '通过LBS灯管集成人体感应和蓝牙、雷达感应模快，通过蓝牙进行信号传递。高度集成智能感应、传感、通讯、摄像头、控制模块，可实现复杂照明、导航、感应、节能的目的。'
        this.serviceContent2 = ''
      } else if (val === '2-5') {
        this.listTitleImg1 = listTitleImg251
        this.listTitleImg2 = listTitleImg252
        this.goodnessImg = goodnessImg25
        this.goodnessListTitle1 = '全程守护'
        this.goodnessList1 = '场库、园区、楼宇智慧化建设、升级改造，从施工到云端运营全程守护。'
        this.goodnessListTitle2 = '全方位托管运营'
        this.goodnessList2 = '全方位托管运营，最大降低车场运营成本。'
        this.serviceList1 = serviceList251
        this.serviceList2 = serviceList252
        this.serviceList3 = serviceList253
        this.serviceList4 = serviceList254
        this.serviceList5 = serviceList255
        this.serviceList6 = serviceList256
        this.streamer1 = '32寸LCD全彩收费一体机'
        this.streamer2 = '车牌识别相机'
        this.streamer3 = '手持收费终端'
        this.streamer4 = '高速道闸'
        this.serviceContent1 = '一体化设计，施工简便；32寸“室外全场彩LCD液晶显示；支 持自定义广告发布。'
        this.serviceContent2 = '200万像素CMOS传感器；80m有效红外距离1080*1920@30fps； 视频识别免切地感。'
        this.serviceContent3 = '本地收费系统因断电设备故障等异常情况时应急使用；复式收 费，解决高峰时段出口拥堵'
        this.serviceContent4 = '1，3，5秒可选；智能雷达防砸车；500万次免维护设计。'
        this.serviceContent5 = '13.3寸可视对讲；一键呼叫；服务评价。'
        this.serviceContent6 = '200万像素夜间可视；威胁报警；支持POE供电。'
      }
    }
  }
}
</script>

<style lang="less">
.content-pc {
  width: 100%;
  padding-top: 100px;
  .scroll-content {
     width: 100%;
     height: calc(100% - 100px);
     position: fixed;
     top: 100px;
     left: 0;
     overflow-y: scroll;
     &::-webkit-scrollbar {
       display: none; /* Chrome Safari */
     }
  }
  .title {
    position: absolute;
    top: 30px;
    text-align: center;
    width: 100%;
    height: 34px;
    font-size: 34px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #111C61;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    .header-content {
      width: 1191px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        cursor: pointer;
        width: 234px;
        height: 72px;
        h1 {
          visibility: hidden;
        }
        img {
          width: 224px;
          height: 62px;
        }
      }
      .menu {
        height: 72px;
        background-color: #99c;
        .el-menu.el-menu--horizontal {
            border-bottom: none;
        }
        .el-menu {
          height: 72px;
          font-size: 22px;
          .el-menu-item,.el-submenu__title,.el-submenu {
            font-size: 22px;
          }
          .el-menu-item.el-menu-item.is-active,.el-submenu.is-active .el-submenu__title {
            border-bottom:none !important;
          }
          .el-menu-item.el-menu-item.is-active {
            border-bottom-color: transparent !important;
          }
          .menu-buttom {
            width: 100%;
            height: 4px;
            display: flex;
            justify-content: center;
            .active-line {
              width: 75%;
              height: 4px;
              border-radius: 4px;
              background-color: #0650FF;
            }
          }
        }
      }
    }
  }
  .layout {
    width: 100%;
    .banner {
      position: relative;
      width: 1920px;
      height: 498px;
      overflow: hidden;
      img {
        width: 1920px;
        height: 498px;
        cursor: pointer;
      }
      .indicators {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        div {
          margin: 0 5px;
        }
        .van-swipe__indicator {
          width: 16px;
          height: 16px;
          background: #FFFFFF;
          opacity: 0.6;
          border-radius: 8px;
          cursor: pointer;
        }
        .van-swipe__indicator--active {
          width: 40px;
          height: 16px;
          background: #FFFFFF;
          opacity: 0.9;
          border-radius: 8px;
        }
      }
    }
  }
  .goodness {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 800px;
    .goodness-content {
      height: 532px;
      width: 1191px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      padding: 79px 100px 0 87px;
      .left {
        flex: 1;
        .goodness-list {
          &:nth-child(2) {
            margin-top: 32px;
          }
          .list-title {
            img {
              width: 38px;
              vertical-align:middle
            }
            span {
              margin-left: 18px;
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 600;
              color: #2F3C8F;
              line-height: 44px;
              vertical-align:middle
            }
          }
          .list-mian {
            margin-top: 34px;
            width: 443px;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #2F3C8F;
            line-height: 42px;
            opacity: 0.9;
          }
        }
      }
      .right {
        width: 485px;
        height: 374px;
        img {
          width: 485px;
          height: 374px;
        }
      }
    }
  }
  .service {
    position: relative;
    width: 100%;
    height: 824px;
    display: flex;
    justify-content: center;
    .service-content {
      width: 1191px;
      height: 824px;
      padding-top: 105px;
      display: flex;
      flex-direction: column;
      display: -webkit-flex;
      align-items: center;
      .service-btn {
        width: 1191px;
        height: 60px;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        .btn-list {
          z-index: 999;
          position: relative;
          width: 100%;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #5C5C66;
          .border-bottom {
            position: absolute;
            left: 50%;
            bottom: 0px;
            width: 55%;
            height: 4px;
            transform: translateX(-50%);
          }
          img {
            margin-right: 5px;
            height: 26px;
            vertical-align:middle
          }
        }
        .active-btn {
          font-weight: 500;
          color: #0139DF;
          .border-bottom {
            background: #0139DF;
          }
        }
      }
      .service-img {
        width: 711px;
        height: 550px;
        margin-top: 27px;
        img {
          width: 711px;
          height: 550px;
        }
      }
    }
  }
  .service-copy {
    position: relative;
    width: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    .service-content {
      position: relative;
      margin-top: 180px;
      width: 1200px;
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .inline-service-list {
        position: absolute;
        left: 0;
        bottom: 12px;
        width: 1200px;
        font-size: 20px;
        font-family: SimHei;
        font-weight: 400;
        color: #111C61;
        margin-bottom: 37px;
        cursor: pointer;
      }
      .service-list {
        position: relative;
        width: 559px;
        margin-bottom: 37px;
        font-size: 20px;
        height: 380px;
        font-family: SimHei;
        font-weight: 400;
        color: #111C61;
        cursor: pointer;
        img {
          width: 559px;
          height: 294px;
        }
        &:nth-child(1) {
          margin-right: 77px;
        }
        &:nth-child(3) {
          margin-right: 77px;
        }
        div {
          margin-top: 21px;
        }
        .tip {
          margin: 0;
          color: rgba(102, 102, 102, 1);
        }
        .streamer {
          margin: 0;
          position: absolute;
          left: 0;
          top: 232px;
          width: 100%;
          height: 62px;
          background: #0139DF;
          opacity: 0.6;
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 62px;
          text-align: center;
        }
      }
    }
  }
  .about {
    position: relative;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 800px;
    background: #F2F8FE;
    .about-content {
      width: 1191px;
      height: 570px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #2F3C8F;
      line-height: 42px;
      opacity: 0.9;
      cursor: default;
    }
  }
  .contact {
    position: relative;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    width: 100%;
    height: 484px;
    background: #0139DF;
    box-shadow: 0px 10px 20px 0px rgba(0,33,67,0.1);
    .title {
      position: absolute;
      top: 30px;
      text-align: center;
      width: 100%;
      height: 34px;
      font-size: 34px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
    }
    .contact-content {
      position: absolute;
      top: 180px;
      display: flex;
      display: -webkit-flex;
      width: 1191px;
      justify-content: space-between;
      flex-wrap: wrap;
      .part-1 {
        width: 42%;
        height: 100px;
        margin-top: 20px;
      }
      .part-2 {
        width: 58%;
        height: 100px;
        margin-top: 20px;
      }
      .row-1 {
        font-size: 22px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #FFFFFF;
      }
      .row-2 {
        margin-top: 20px;
        font-size: 20px;
        font-family: Titillium Web;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28px;
      }
    }
    border-bottom: 1px solid #fff;
  }
  .footer {
    width: 100%;
    height: 105px;
    background: #0139DF;
    box-shadow: 0px 10px 20px 0px rgba(0,33,67,0.1);
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    .footer-content {
      display: flex;
      display: -webkit-flex;
      justify-content: space-between;
      width: 1191px;
      height: 105px;
      line-height: 105px;
      font-size: 21px;
      font-family: Myriad Pro;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}
.content-mobile {
  width: 100%;
  height: calc(100%);
  padding-top: 205px;
  .scroll-content {
     width: 100%;
     height: calc(100% - 205px);
     position: fixed;
     top: 205px;
     left: 0;
     overflow-y: scroll;
     &::-webkit-scrollbar {
       display: none; /* Chrome Safari */
     }
  }
  .title {
    width: 100%;
    height: 87px;
    text-align: center;
    font-size: 87px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #111C61;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 205px;
    padding: 0 77px 0 77px;
    background-color: #fff;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: 384px;
      height: 118px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .menu {
    height: 205px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: flex-end;
    .menu-text {
        display: inline-block;
        margin-right: 54px;
        font-size: 77px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #0650FF;
        line-height: 108px;
        vertical-align:middle
    }
    img {
      width: 82px;
      height: 70px;
      vertical-align:middle;
      cursor: pointer;
    }
    .menu-close {
      width: 79px;
      height: 79px;
      margin-left: 15px;
    }
  }
  .el-drawer {
    // padding-left: 143px;
    .menu {
      padding-right: 90px;
    }
    .menu-list {
      width: calc(100%);
      .menu-title {
        width: 100%;
        height: 77px;
        margin-top: 100px;
        padding-right: 90px;
        padding-left: 143px;
        font-size: 77px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #111C61;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 77px;
          height: 46px;
        }
      }
      .menu-items {
        height: 64px;
        font-size: 67px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #111C61;
        opacity: 0.8;
      }
      .menuActive {
        color: #0650FF;
      }
      .menu-not-show {
        transform: rotate(180deg);
      }
      .menu-group {
        margin-top: 59px;
        margin-bottom: 100px;
        padding: 50px 0 100px 143px;
        padding-left: 143px;
        height: 992px;
        background: #F3F4F4;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
  .banner-mobile {
    width: 1920px;
    height: 794px;
    overflow: hidden;
    img {
      width: 1920px;
      height: 794px;
    }
    .van-swipe__indicator {
      width: 41px;
      height: 41px;
      background: #FFFFFF;
      opacity: 0.6;
      border-radius: 20px;
      margin-bottom: 60px;
    }
    .van-swipe__indicator--active {
      width: 102px;
      height: 41px;
      background: #FFFFFF;
      opacity: 0.9;
      border-radius: 20px;
    }
  }
  .service {
    width:100%;
    height: 2284px;
    padding: 100px 123px 310px 79px;
    .service-content {
      margin-top: 100px;
      width: 100%;
      .service-btn {
        width: calc(100%);
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        .btn-list {
          height: 74px;
          font-size: 77px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #5C5C66;
        }
        .active-btn {
          height: 74px;
          font-size: 77px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #0139DF;
          &::after {
            content:'';
            display:block;
            width:100%;
            margin-top: 38px;
            height: 9px;
            background: #0139DF;
          }
        }
      }
      .service-img {
        margin-top: 205px;
        width: calc(100%);
        height: 1408px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .service-copy {
    position: relative;
    width: 100%;
    background: #F3F8FD;
    padding-top: 100px;
    .service-content {
      background-color: #fff;
      position: relative;
      margin-top: 177px;
      padding: 0 77px 167px 77px;
      width: 100%;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;
      .inline-service-list {
        width: 100%;
        font-size: 77px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #2F3C8F;
        line-height: 108px;
        opacity: 0.9;
      }
      .service-list {
        width: 100%;
        margin-bottom: 37px;
        margin-top: 77px;
        font-size: 77px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #2F3C8F;
        line-height: 108px;
        opacity: 0.9;
        img {
          width: 1766px;
          height: 934px;
        }
        &:nth-child(1) {
          margin-right: 77px;
        }
        &:nth-child(3) {
          margin-right: 77px;
        }
        div {
          margin-top: 21px;
        }
        .tip {
          margin: 0;
          color: rgba(102, 102, 102, 1);
        }
        .streamer {
          width: 100%;
          margin-top: 14px;
          font-size: 87px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #2F3C8F;
        }
      }
    }
  }
  .goodness {
    position: relative;
    width: 100%;
    padding: 100px 113px 154px 77px;
    .goodness-content {
      width: 100%;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 79px 100px 0 87px;
      .top {
        width: 1242px;
        height: 957px;
        margin-top: 177px;
        img {
          width: 1242px;
        height: 957px;
        }
      }
      .bottom {
        width: 100%;
        margin-top: 100px;
        .goodness-list {
          &:nth-child(2) {
            margin-top: 32px;
          }
          .list-title {
            img {
              width: 90px;
              vertical-align:middle
            }
            span {
              margin-left: 51px;
              font-size: 82px;
              font-family: PingFang SC;
              font-weight: 600;
              color: #2F3C8F;
              line-height: 108px;
              vertical-align:middle
            }
          }
          .list-mian {
            margin-top: 82px;
            font-size: 77px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #2F3C8F;
            line-height: 128px;
            opacity: 0.9;
          }
        }
      }
    }
  }
  .about {
    padding: 100px 123px 79px;
    width: 1920px;
    height: 4000px;
    background: #F3F8FD;
    .about-content {
      margin-top: 100px;
      font-size: 77px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #2F3C8F;
      line-height: 128px;
      opacity: 0.9;
    }
  }
  .contact {
    width:cal(100%);
    height: 1820px;
    padding: 100px 123px 310px 79px;
    background-color: #0139DF;
    .title {
      font-size: 87px !important;
      font-family: PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
    }
    .contact-content {
      margin-top: 143px;
      .part-1 {
        margin-top: 128px;
        .row-1 {
          font-size: 62px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #FFFFFF;
        }
        .row-2 {
          margin-top: 49px;
          font-size: 52px;
          line-height: 92px;
          font-family: Titillium Web;
          font-weight: 400;
          color: #FFFFFF;
        }
      }
    }
  }
  .footer {
    width: 1920px;
    height: 620px;
    background: #0139DF;
    box-shadow: 0px 26px 51px 0px rgba(0,33,67,0.1);
    border-top: 3px solid #FFFFFF;
    .footer-content {
      margin-top: 115px;
      font-size: 77px;
      font-family: Myriad Pro;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 89px;
      text-align: center;
      .list {
        margin-top: 82px;
      }
    }
  }
}
</style>

