import Vue from 'vue'
import {
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  Overlay,
  Collapse,
  CollapseItem
} from 'vant'

Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Overlay)
Vue.use(Collapse)
Vue.use(CollapseItem)
