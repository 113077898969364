import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './utils/element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'vant/lib/index.css'
import './utils/vant'
// import '@vant/touch-emulator';
import 'amfe-flexible'
import './styles/base.less'
import moment from 'moment'

// 定义全局过滤器处理时间格式
Vue.filter('fromNow', time => {
  return moment(time).fromNow()
})

// 处理时间为中文
moment.locale('zh-cn')

Vue.config.productionTip = false
new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
